$(document).on('show.bs.modal', '.modal', function () {
    let zIndex = 1040 + (10 * $('.modal:visible').length);
    $(this).css('z-index', zIndex);
    setTimeout(function () {
        $('.modal-backdrop').not('.modal-stack').css('z-index', zIndex - 1).addClass('modal-stack');
    }, 0);
});

/**
 * Redirects the browser to the home page by default,
 * or to a uri if the url parameter is passed.
 * @method redirect
 * @param  {String} url URI resource (e.g. '/?route=maintenance')
 */
function redirect(url) {
    let str = (url == null) ? '' : url;
    window.location = "https://" + window.location.hostname + str;
}

/**
 * Takes a lowercase string as input and returns a Proper Case string. [sysbiocube => Sysbiocube]
 * @method properCase
 * @param  {[type]}   string [description]
 * @return {[type]}          [description]
 */
function properCase(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

/**
 * Takes as input, an underscore '_' delimited string and returns a Title Case string. [title_case => Title Case]
 * @method humanize
 * @param  {String} str String passed into function from user call
 * @return {String}     String returned from function in Title Case
 */
function humanize(str) {
    let frags = str.split('_');
    for (let i = 0; i < frags.length; i++) {
        frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(' ');
}

function humanize_filesize(bytes, decimals = 2) {
    if (!bytes) return '';
    let i = Math.floor(Math.log(bytes) / Math.log(1024));
    return ( bytes / Math.pow(1024, i) ).toFixed(decimals) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
}

/**
 * Calls the global bootstrap modal and displays parameter info on screen
 * @method showMessage
 * @param  {String}    windowTitle  HTML markup to fill title space in the top next to the logo]
 * @param  {String}    alertClass   Bootstrap class [alert-danger, alert-success, alert-warning, alert-info]
 * @param  {String}    alertMessage HTML markup to fill alert box
 * @param  {String}    bodyMessage  HTML markup to fill modal bodyMessage
 * @param  {Boolean}   largeModal   Boolean parameter to indicate large size modal
 */
function showMessage(windowTitle, alertClass, alertMessage, bodyMessage, footerButtons, largeModal = false) {
    let messageModal = $('#messageModal');
    let alertSelector = $('#alertMessage');

    $('#windowTitle').html(windowTitle);
    alertSelector.removeClass();

    if (alertClass) {
        alertSelector.addClass("alert " + alertClass);
    }

    alertSelector.html(alertMessage);
    $('#bodyMessage').html(bodyMessage);
    $('#footerSection').html(footerButtons);

    let myModal = new bootstrap.Modal(document.getElementById('messageModal'), {backdrop: false, keyboard: false, focus: true});

    myModal.show();
}

function showSecondaryMessage(windowTitle, alertClass, alertMessage, bodyMessage, largeModal = false) {
    let messageModalSecondary = $('#messageModalSecondary');
    let alertSelector = $('#alertMessageSecondary');

    if (largeModal) {
        messageModalSecondary.find('div.modal-dialog').addClass('modal-xl');
    } else {
        messageModalSecondary.find('div.modal-dialog').removeClass('modal-xl');
    }

    $('#windowTitleSecondary').html(windowTitle);
    alertSelector.removeClass();

    if (alertClass) {
        alertSelector.addClass("alert " + alertClass);
    }

    alertSelector.html(alertMessage);
    $('#bodyMessageSecondary').html(bodyMessage);
    messageModalSecondary.modal('show');
}

function array_unique(array) {
    return array.filter(function (value, index, self) {
        return self.indexOf(value) === index;
    });
}

function htmlDecode(input) {
    let doc = new DOMParser().parseFromString(input, "text/html");
    return doc.documentElement.textContent;
}

function trimFromLast(char, str) {
    return str.substr(0, str.lastIndexOf(char));
}

function trimFromFirst(char, str) {
    return str.substr(str.indexOf(char) + 1);
}

$(document).ready(function () {


    // Code for Dropdown Transition movement
    $('.dropdown-animate').on("show.bs.dropdown", function (e) {
        $(this).next('.dropdown-menu').toggle(500);
    });

    $('.dropdown-animate').on("hide.bs.dropdown", function (e) {
        $(this).next('.dropdown-menu').toggle(300);
    });

    // Logic for Data Files Tab on "Explore Data" page
    $('#dataFileUL').on("click", function(){
        $(this).next('ul').toggle(550);
    });

    $('.dropdown-item').on("click", function(){
        $('#dataFileUL').next('ul').toggle(400);
    });

});